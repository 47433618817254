import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { Row, Col } from "reactstrap";
import Section from "../components/section";
import Style from "./about.module.css";

export default ({ data }) => (
  <Layout>
    <Section className="home-container" id="home">
      <Row className="pb-4">
        <Col>
          <Link className={Style.smallFont} to="/">
            Back to frontpage
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <h1>Imprint</h1>
        </Col>
      </Row>
      <Row className="imprintBody">
        <Col>
          <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
        </Col>
      </Row>
    </Section>
  </Layout>
);

export const query = graphql`
  query Imprint {
    markdownRemark(fileAbsolutePath: { regex: "/content/about/imprint.md$/" }) {
      html
    }
  }
`;
