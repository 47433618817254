import React from "react";
import { Container, Row, Col } from "reactstrap";

import "./page.scss";

const Section = ({ id, title, children }) => (
  <Container id={id}>
    <Row>
      {title ? (
        <Col>
          <h1>{title}</h1>
        </Col>
      ) : null}
    </Row>
    {children}
  </Container>
);

export default Section;
